import each from './each';

export default function(arr, key) {
  const res = {};
  each(arr, item => {
    const value = item[key];
    if (value) {
      res[value] = 1;
    }
  });
  return Object.keys(res);
};