import matches from './matches';


export default function(node, selector) {
  while (node) {
    if (matches(node, selector)) {
      return node;
    }
    else {
      node = node.parentElement;
    }
  }
  return null;
}