export default function each(arr, fn) {
  if (Array.isArray(arr) || arr instanceof NodeList) {
    [].forEach.call(arr, fn);
  } else if (typeof arr === 'object') {
    for (let key in arr) {
      if (arr.hasOwnProperty(key)) {
        fn(arr[key], key);
      }
    }
  }
}