export const matchesMethodName = (() => {
  const list = ['matches', 'matchesSelector', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'];
  for (let i = 0, len = list.length; i < len; i++) {
    if (Element.prototype[list[i]]) {
      return list[i];
    }
  }
  return false;
})();


export default function matches(node, selector) {
  if (!matchesMethodName) {
    return false;
  }
  return node[matchesMethodName](selector);
}